import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SecuredTableComponent from './SecuredTableComponent'

const SecuredT2 = () => {

  const dataObj = useStaticQuery(graphql`
		query SecuredT2Query {
			contentfulComponentJsonTable(tableName: {eq: "RatsAndFeesSecuredT2"}) {
				data {
					td1 {
						content
						background
						color
						fontSize
					}
					td2 {
						content
						background
						color
						fontSize
					}
					td3 {
						content
						background
						color
						fontSize
					}
				}
				content1
				content2
			}
		}
	`)

  return (
    <SecuredTableComponent items={dataObj.contentfulComponentJsonTable} />
  )
}

export default SecuredT2