import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import UnsecuredTableComponent from './UnsecuredTableComponent'

const UnsecuredT4 = () => {

  const dataObj = useStaticQuery(graphql`
		query UnsecuredT4Query {
			contentfulComponentJsonTable(tableName: {eq: "RatsAndFeesUnsecuredT4"}) {
				data {
					td1 {
						content
						background
						color
						fontSize
					}
					td2 {
						content
						background
						color
						fontSize
					}
					td3 {
						content
						background
						color
						fontSize
					}
				}
			}
		}
	`)

  return (
    <UnsecuredTableComponent items={dataObj.contentfulComponentJsonTable.data} />
  )
}

export default UnsecuredT4